@use "./config/" as *;
@forward './main.scss';
@forward './events/featured-events';
@forward './events/eventstrip';
@forward './swiper/swiper-bundle';
@forward './module/banner';
@forward './events/eventlisting-hero';
@forward './events/event-calendar';
@forward './events/eventlisting';


.waf-event-strip{
    &.waf-component{
        padding-block: var(--space-20);
    }
}

@include mq(col-desktop){
    .waf-row:not(.waf-row-showcase-strip) {
        .waf-event-strip {
            .card-list>*{
                width: calc(100% / 3 - (2 * var(--space-6) / 3));
            }
        }
    }
    .waf-event-strip{
        .card-list{
            margin-right: 0;
        }
    }
}