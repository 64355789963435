@use "../config/" as *;
.waf-events {
    @extend %mx-2-neg;
    &.waf-component {
        @extend %pl-4;
        @extend %pr-0;
    }
    &.waf-featured-event {
        &.waf-component {
            @extend %py-0;
            @extend %mb-0;
        }
        .img-box {
            img {
                position: unset;
                aspect-ratio: 21 / 10;
            }
        }
        .card-item {
            background: unset;
            @extend %p-0;
            &::after {
                display: none;
            }
        }
    }
    .layout-wrapper {
        max-width: 100%;
    }

    .title {
        @extend %title-40;
        @extend %neutral-100;
        @extend %mb-8;
        @include title-with-icon(null, megaphone-fill, 18);
        &:before {
            justify-content: flex-start;
            @extend %neutral-50;
        }
    }
    .card {
        &-wrapper {
            @include card-count(1.2, var(--space-2));
        }
        &-item {
            background: linear-gradient(268.76deg, var(--primary-90) -0.19%, var(--primary-50) 99.83%);
            flex-shrink: 0;
            @extend %border-radius-r;
            @extend %hidden;
            @extend %px-9;
            @extend %py-6;
            &::after{
                content: unset;
                background-color: hsla(var(--hsl-pure-black-0),20%);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @extend %d-none;
            }
            &:hover{
                &::after{
                    content: "";
                }
            }
        }
        &-thumbnail{
            position: relative;
            z-index: 1;
        }
    }
    .img-box {
        @extend %bg-transparent;
        img {
            object-fit: contain;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        @extend %d-none;
    }
}
.waf-sport-events {
    position: relative;
    &.waf-component {
        @extend %mb-20;
        @extend %py-0;
    }
    .title {
        @extend %mb-2;
        &:before {
            @extend %mb-2;
            @extend %neutral-40;
            @include icon(magnifying-glass-plus, 24);
        }
    }
    .desc {
        font-weight: 700;
        @extend %neutral-70;
    }
    .waf-head {
        @extend %mb-10;
    }
    .head-tab {
        a {
            @extend %btn-default-dark;
            @extend %gap-2;
            &:after {
                @include icon(arrow-right, 19);
            }
        }
    }
    .card {
        &-item {
            background: transparent;
            max-width: 30.2rem;
            max-height: 30.2rem;
            @extend %p-0;
            @extend %border-radius-m;
            @extend %relative;
            &:hover{
                .card-thumbnail{
                    img{
                        transition: 500ms ease-in-out;
                        transform: scale(1.03);
                    }
                }
            }
        }
        &-thumbnail {
            a {
                @extend %relative;
                &:after {
                    content: '';
                    background: linear-gradient(180deg, hsl(var(--hsl-pure-black-0)/0) 0%, hsl(var(--hsl-pure-black-0)/.5) 100%);
                    @extend %position-center;
                    @extend %w-100;
                    @extend %h-100;
                }
            }
            img{
                transition: 500ms ease-in-out;
            }
        }
        &-content {
            left: 2.4rem;
            @extend %title-40;
            @extend %neutral-0;
            @extend %position-b-l;
            @extend %zindex-content;
        }
        &-title {
            @extend %mb-6;
        }
    }
    .img-box img {
        object-fit: cover;
    }
    .swiper-button-prev,
    .swiper-button-next {
        border-color: var(--primary-50);
        top: unset;
        bottom: calc(100% + var(--space-10));
        transform: unset;
        @extend %d-none;
        &::after {
            color: var(--primary-50);
            font-size: 2.4rem;
        }
        &:hover{
            border-color: var(--primary-55);
            &::after{
                @extend %primary-55;
            }
        }
        &:focus{
            border-color: var(--primary-85);
            &::after{
                @extend %primary-85;
            }
        }
        &.swiper-button-disabled {
			border-color: var(--neutral-20);
			&::after{
				color: var(--neutral-40);
			}
		}
    }
    .swiper-button-prev {
        right: calc(((var(--window-inner-width) - var(--container-max-width))) + var(--swiper-button-width) + var(--space-2));
        left: unset;
    }
    .swiper-button-next {
        right: calc((var(--window-inner-width) - var(--container-max-width)));
    }
}
@include mq(col-tablet) {
    .waf-events {
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
        &.waf-featured-event {
            &.waf-component {
                padding-left: 0;
            }
        }
        &.waf-sport-events {
            .card-wrapper {
                gap: 0;
            }
        }
        .card-wrapper {
            overflow-x: hidden;
            @include card-count(3, var(--space-6));
        }
    }
    .waf-component.waf-sport-events {
        padding-inline: var(--space-0);
    }
    .waf-sport-events {
        margin-top: 0;
        .title {
            font-size: 4rem;
            margin-bottom: var(--space-4);
            &::before {
                margin-bottom: var(--space-4);
            }
        }
        .desc {
            font-size: 1.6rem;
            max-width: 26%;
        }
        .swiper-button-prev,
        .swiper-button-next {
            display: flex;
        }
        .preview-swiper {
            overflow-x: clip;
            overflow-y: visible;
            margin-right: calc(-1 * (var(--window-inner-width) - var(--container-max-width)) / 2);
        }
        .card-wrapper {
            overflow: visible;
        }
    }
}