@use "../config/"as *;

.waf-eventlisting {
    &.waf-event-calendar {
        --_card-left-width: 9.6rem;
        .card {
            &-title {
                text-overflow: unset;
                white-space: unset;
                overflow: visible;
                line-height: 1.5;
                order: -1;
                @extend %w-100;
                @extend %m-0;
            }

            &-tag {
                @extend %mt-3;
                .text {
                    min-height: 2.4rem;
                    padding-inline: 1rem;
                    @extend %font-10-pb;
                }
            }

            &-left {
                width: var(--_card-left-width);
                @extend %mt-2;
                @extend %pr-2;
                @extend %text-right;
                .card-tag {
                    @extend %gap-1;
                    .text {
                        min-height: 1.8rem;
                        border-color: var(--neutral-20);
                        font-size: 1rem;
                        font-weight: 600;
                        @extend %neutral-70;
                    }
                }
            }

            &-right {
                width: calc(100% - var(--_card-left-width));
                @extend %pl-3;
                @extend %ml-0;
                @extend %flex-column;
                > a {
                    order: -1;
                }
            }

            &-meta {
                @extend %my-1;
            }

            &-date {
                @extend %text-s;
                @extend %neutral-70;
            }
        }

        .meta {
            &-category {
                width: max-content;
                overflow: unset;
                text-overflow: unset;
                white-space: unset;
            }
        }

        .body-filter-content,
        .head-tab-list {
            @extend %mt-0;
        }

        .body {
            &-tabs-container {
                &:has(.list-view) {
                    background: unset;
                    border: unset;
                }

            }
        }
        .card-listing-wrapper {
            @include border(1, neutral-20, 10);
        }

        .no-data {
            &-head {
                @extend %p-6-4;
            }

            &-container {
                @extend %m-4;
                @extend %neutral-70;
            }
        }
        .site-search-form, .head-tab-list {
            z-index: var(--z-events-page-above-loader);
        }
        .head-tab-list {
            border-top: none;
            @extend %relative;
            @extend %pt-0;
        }
        .tabs-options-list .card {
            &-item {
                width: 14rem;
            }
            &-label {
                justify-content: center;
                @extend %w-100;
            }
        }
        
        .toastui-calendar {
            &-daygrid-cell.today::before {
                pointer-events: none;
                @extend %bg-neutral-5;
            }
        }
    }
}

.waf-event-calendar {
    .filter-section {
        .select-list {
            @extend %gap-0;
        }
        .label-wrapper {
            label::before {
                @extend %p-2;
            }
            input:checked + label::after {
                width: .45rem;
                height: 1.1rem;
                top: .3rem;
                left: .8rem;
            }
        }
    }
    .search-input {
        border-radius: 0;
        background: unset;
    }
    .card {
        &-tag {
            order: 1;
        }
        &-item {
            align-items: flex-start;
            &.postponed {
                align-items: center;
                .card-left {
                    margin-top: 0
                }
            }
        }

        &-source {
            @extend %flex;
            @extend %flex-wrap;
            @extend %gap-2;

            .btn-site .text {
                border-color: var(--primary-50);
                @extend %primary-50;
            }

            >a {
                width: max-content;
                @extend %d-block;
            }
        }

        &-date {
            @extend %text-right;

            span {
                @extend %d-block;
            }
        }
    }
    .view-tabs {
        @extend %m-y-5-8;
    }

    .meta {
        &-origin {
            &:before {
                @extend %d-none;
            }
        }

        &-category-link:not(:last-child)::after,
        &-sport-link:not(:nth-last-child(1 of .meta-sport-link))::after {
            content: "/";
            @extend %neutral-70;
        }
        &-sport:not(:last-child)::after {
            content: "•";
            @extend %neutral-70;
        }

        &-category-link,
        &-sport-link,
        &-sport {
            width: max-content;
            @extend %flex-n-c;
            @extend %gap-1;
        }

        &-category {
            &:before {
                display: none;
            }
        }

        .flag {
            width: 1.2rem;
            aspect-ratio: 1/1;
            object-fit: contain;

        }
    }

    .venue {
        @extend %neutral-70;
        @extend %font-10-pb;
    }

    .image {
        width: auto;
        height: 2.4rem;
        object-fit: contain;
        @extend %w-100;
    }

    .event {
        &-meta {
            flex-wrap: wrap;
            @extend %flex-n-c;
            @extend %gap-1;
        }

        &-status {
            .text {
                font-weight: 700;
                @extend %d-block;
                @extend %text-s;
                @extend %capitalize;
            }
        }

        &-tag {
            @extend %flex-n-c;
            @extend %gap-2;
        }

        &-category,
        &-sport,
        &-sport-type {
            @extend %flex-wrap;
            @extend %flex-n-c;
            @extend %gap-1;
        }
        &-sport + .event-sport::before {
            content: "/";
            @extend %neutral-70;
        }
        &-category:has(+.event-sport .meta-sport-link)::after {
            @include icon(chevron-right, 12);
            @extend %neutral-70;
        }
    }
    .meta-sport-link:nth-last-child(1 of .meta-sport-link):has(+.event-sport-type .meta-sport)::after {
        @include icon(chevron-right, 12);
        @extend %neutral-70;
    }

    .date {
        font-weight: 700;
    }

    .cancelled {
        @extend %negative-70;
    }

    .postponed {
        @extend %neutral-70;
    }

    .rescheduled {
        @extend %primary-50;
        .card-left .card-tag {
            justify-content: flex-end;
            @extend %m-y-0-1;
            .text:hover {
                background-color: unset
            }
        }
    }

    .filter-section {
        @extend %flex-sb-c;
        .body {
            height: unset;
            flex: 1;
        }
        &-wrapper {
            height: calc(100% - var(--header-height) - var(--space-3));
            @extend %flex-column;
        }

        .dropdown {
            &-item.active .dropdown-list {
                z-index: var(--z-default);
            }
        }

        .toggle-wrap {
            height: 3.6rem;
            @extend %flex-n-c;
            @extend %px-4;
        }

        .btn-clear{
            .btn-text::after{
                @extend %primary-50;
            }
        }
    }

    .calendar-view {
        border: .1rem solid var(--neutral-20);
        @extend %border-radius-m;
        @extend %bg-neutral-0;
        .filter-section {
            @extend %mt-0;
        }

        .tabs-options-list {
            border-block: 0;
            @extend %mt-0;
            .card-label:hover {
                cursor: pointer;
            }
            .card-item.disabled {
                @extend %d-none;
            }
        }

        .body-filter-content {
            border-radius: unset;
            background-color: unset;
            border-bottom: .1rem solid var(--neutral-20);
            @extend %m-0;
            @extend %py-4;
        }

        .calendar-container {
            border-top: .1rem solid var(--neutral-20);
        }

        .card-listing-wrapper {
            border: none;
        }
    }
    .pagination-wrap{
        @extend %mb-5;
    }
    .disabled{
        opacity: .5;
        .card-label,.badge{
            @extend %neutral-100;
        }
    }
    .toastui-calendar {
        &-holiday-sun {
            color: #333 !important;
        }
        &-daygrid-cell:last-child .toastui-calendar-grid-cell-date {
            color: #333 !important;
            &[style*="0.4"] {
                color: rgba(#333, .4) !important;
            }
        }
    }
}

@include mq(col-tablet) {
    .upfront-filter .waf-event-calendar .filter-section {
        .dropdown-item.active .dropdown-list {
            width: 37rem;
            height: 45rem;
        }
        .badge {
            display: flex;
        }
    }
    .waf-event-calendar {
        .filter-section {
            &-wrapper {
                height: unset;
            }
            .body {
                width: 100%;
            }

            .toggle-wrap {
                order: 1;
                margin-top: var(--space-4);
            }
            .btn-clear{
                display: none;
            }
        }
        .calendar-view {
            border-radius: var(--border-radius-l);
            .body-filter-content {
                padding-inline: var(--space-2);
                border-bottom: 0;
            }
            .tabs-options-list {
                margin-inline: var(--space-6);
            }
            .toastui-calendar-layout {
                border-radius: 0 0 var(--border-radius-l) var(--border-radius-l);
            }
        }
        .venue {
            font-size: 1.4rem;
        }
        .date, .year {
            font-size: 1.4rem;
        }
        .meta .flag {
            width: 1.6rem;
        }
    }

    .waf-eventlisting.waf-event-calendar {
        .card {
            &-left .card-tag .text {
                min-height: 1.8rem;
            }
            &-right {
                padding-right: 0;
            }
            &-meta, &-label {
                width: 100%;
            }
            &-label {
                justify-content: center;
            }
        }
        .tabs:not(.head-tab-list){
            margin: 0 0 0 auto;
        }
    }
}

@include mq(col-lg){
    .upfront-filter .waf-event-calendar .filter-section .dropdown-item {
        width: 21.4rem;
        min-width: 21.4rem;
    }
    .waf-eventlisting.waf-event-calendar{
        --title-max-width: calc(100% - 43.5rem - var(--space-4));
        .card{
            &-item {
                padding-block: var(--space-5);
                cursor: default;
            }
            &-title {
                font-size: 2.4rem;
            }
            &-tag {
                margin-block: 0;
                position: unset;
                order: -1;
                align-items: flex-start;
                justify-content: space-between;

                .text {
                    min-height: 3rem;
                    font-size: 1.4rem;
                }
            }
            &-source .text {
                padding-inline: var(--space-4);
                &:hover {
                    border-color: var(--primary-55);
                    background-color: unset;
                    color: var(--primary-55);
                }
            }

            &-right {
                flex-wrap: wrap;
                @include flex-config(flex,row);
                > {
                    a, .card-title {
                        max-width: var(--title-max-width);
                    }
                }
            }
            &-title {
                width: 100%;
                margin-right: var(--space-4);
            }
        }
        .event-tag {
            @include position-combo("y-center", var(--space-6));
            .text:hover {
                border-color: var(--primary-50);
                background-color: var(--primary-50);
                color: var(--neutral-0);
            }
        }
        .toastui-calendar {
            &-grid-cell-header {
                padding-right: var(--space-2);
            }
            &-grid-cell-more-events {
                height: 2.4rem;
                padding-inline: var(--space-2);
                border: .1rem solid var(--primary-50);
                border-radius: 100vh;
                @include flex-config(flex, null, null, center);
            }
            &-weekday-grid-more-events {
                color: var(--primary-50);
            }
            &-see-more-header {
                gap: var(--space-1);
                text-align: left;
                @include flex-config(flex, row-reverse, flex-end, center);
            }
            &-more-title-day, &-more-title-date {
                color: var(--neutral-70);
            }
            &-more-title-day {
                font: 700 2rem/2.8rem $font-primary;
            }
            &-more-title-date {
                margin-bottom: 0;
                font-size: 2rem;
                line-height: 2.8rem;
            }
            &-popup-button.toastui-calendar-popup-close {
                margin-left: auto;
                position: unset;
                order: -1;
            }
            &-weekday-event-dot {
                display: none;
            }
            &-weekday-event {
                padding-right: 0;
            }
            &-weekday-event-title {
                padding-left: 0;
            }
            &-see-more-container {
                .toastui-calendar-weekday-event {
                    height: auto !important;
                }
                .toastui-calendar-weekday-event-block {
                    position: relative;
                }
                .calendar-event-item {
                    max-width: 100%;
                    width: 100%;
                    position: unset;
                    text-overflow: unset;
                    white-space: normal;
                    &:hover {
                        max-width: 100%;
                        width: 100%;
                        transform: unset;
                    }
                }
            }
        }
        .calendar-event-item {
            width: 100%;
            padding-inline: var(--space-1);
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover {
                max-width: calc(100% - var(--space-4));
                width: calc(100% - var(--space-4));
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    .waf-event-calendar {
        .card-source {
            margin-top: .3rem;
        }
        .image {
            height: 3rem;
        }
    }
}
@media screen and (min-width: 1250px) and (max-width: $lg-max-width) {
    .waf-event-calendar .filter-section .body {
        width: 90%;
    }
}