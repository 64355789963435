@use "../config/" as *;
.sticky-container-eventstrip {
    [class^="col"] {
        position: static;
    }
}
.waf-component {
    &.waf-eventlisting {
        @extend %py-0;
    }
}
.waf-eventlisting {
    @extend %bg-transparent;
    @extend %common-input-swiper-btn;
    .layout-wrapper {
        @include large-wrapper();
    }
    &::before {
        content: unset;
    }
    &:has(.calendar-view.active) {
        .waf-head {
            .title,
            .head-tab-list,
            .site-search-form,
            .toggle-wrap {
                @extend %d-none;
            }
        }
    }
    .head-tab-list {
        @extend %pt-2;
        @extend %mt-4;
        @extend %px-4;
        @extend %mx-4-neg;
        @extend %flex;
        @extend %tabs-underline;
        @include border(1, neutral-20, 10, y);
    }
    .head-wrap {
        > .sub-title {
            @extend %title-40;
            @extend %mb-6;
            @extend %d-none;
        }
    }
    .waf-head {
        .title {
            @extend %d-none;
        }
    }
    .toggle-wrap {
        input[type=checkbox] {
            height: 0;
            width: 0;
            visibility: hidden;
        }
        label {
            @extend %flex-n-c;
            @extend %relative;
            @extend %text-m;
            @extend %gap-2;
            &::before {
                content: ' ';
                cursor: pointer;
                width: 3.2rem;
                height: 1.6rem;
                @extend %relative;
                @extend %d-block;
                @extend %rounded-radius;
                @extend %bg-neutral-5;
                @extend %font-0;
            }
        }
        label:after {
            content: '';
            width: 1.6rem;
            height: 1.6rem;
            transition: 0.3s;
            @extend %position-t-l;
            @extend %bg-neutral-40;
            @extend %circle-radius;
        }
        input:checked + label {
            &::before {
                @extend %bg-informative-10;
            }
        }
        input:checked + label:after {
            transform: translateX(100%);
            @extend %bg-primary-50;
        }
    }
    .filter-section {
        @extend %p-x-3-0;
        @extend %mt-6;
    }
    .priamry-tabs {
        @include border(1, neutral-20, 10, bottom);
        @extend %tabs-underline;
    }
    .body {
        &-tabs-container {
            @include border(1, neutral-20, 10);
            .tabs:not(.head-tab-list) {
                @extend %mt-4;
            }
        }
    }
    .tabs:not(.head-tab-list) {
        margin: 0 auto;
        width: max-content;
        flex-direction: row-reverse;
        @extend %rounded-radius;
        @extend %flex-c-c;
        @extend %bg-neutral-0;
        .tab {
            &-item {
                &.active {
                    .tab-name {
                        min-width: 11.5rem;
                        @extend %btn-fill-text-icon;
                    }
                }
                &:not(.active) {
                    .tab-name {
                        &::after,
                        .tab-text,
                        .btn-text,
                        .text {
                            @extend %primary-50;
                        }
                    }
                }
            }
            &-name {
                flex-direction: row-reverse;
                @extend %px-3;
                @extend %flex-n-c;
                @extend %gap-2;
                @extend %text-mb;
                &[aria-label="List"],
                &[title="List"] {
                    &::after {
                        @include icon(list-bullet, 24);
                    }
                }
                &[aria-label="Calendar"],
                &[title="Calendar"] {
                    &::after {
                        @include icon(calendar-days, 24);
                    }
                }
            }
        }
    }
    .load-more {
        @include border(1, neutral-20, 10, top);
        @extend %py-8;
        @extend %text-center;
        .btn-text {
            @extend %text-mb;
            @extend %primary-50;
        }
    }
    .card {
        &-label {
            @extend %border-radius-s;
            @extend %text-mb;
            @extend %primary-50;
            @extend %flex-n-c;
        }
        &-item {
            @include border(1, neutral-20, 10, bottom);
            @extend %flex-n-c;
            @extend %p-4;
            @extend %relative;
            &:last-child {
                border-bottom: 0;
            }
            &:first-child {
                @include border(1, neutral-20, 10, top);
            }
        }
        &-left {
            width: 6.1rem;
            flex-shrink: 0;
        }
        &-right {
            flex: 1;
            @include border(1, neutral-20, 10, left);
            @extend %pl-4;
            @extend %ml-4;
        }
        &-date {
            @extend %d-block;
            @extend %text-center;
            @extend %text-lb;
            em {
                @extend %d-block;
                @extend %mt-1;
                @extend %text-s;
                @extend %neutral-70;
            }
        }
        &-meta {
            @extend %flex-column-n-n;
            @extend %gap-2;
        }
        &-title {
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(var(--window-inner-width) - 15rem);
            display: inline-block;
            @extend %mt-2;
            @extend %text-lb;
            @extend %mb-4;
            @extend %hidden;
        }
        &-tag {
            flex-wrap: wrap;
            @extend %mt-4;
            @extend %flex-n-c;
            @extend %gap-2;
            .text {
                @extend %tag-outline;
                @extend %text-mb;
            }
        }
        &-action {
            @extend %w-100;
            @extend %h-100;
            @extend %position-t-l;
            .btn-site {
                @extend %d-block;
                @extend %w-100;
                @extend %h-100;
            }
            .text {
                @extend %font-0;
            }
        }
    }
    .meta {
        @extend %text-s;
        @extend %neutral-70;
        @extend %d-block;
        @extend %gap-1;
        @extend %flex-n-c;
        &::before {
            color: var(--neutral-40);
        }
        &-origin {
            &::before {
                @include icon(map-pin, 16);
            }
        }
        &-category {
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(var(--window-inner-width) - 15rem);
            display: inline-block;
            @extend %hidden;
            &::before {
                vertical-align: text-top;
                @extend %mr-1;
                @include icon(trophy, 16);
            }
        }
    }
    .tabs-options-list {
        @include border(1, neutral-20, 10, y);
        @extend %py-4;
        @extend %mt-4;
        .card-item {
            width: auto;
            border: 0;
            @extend %p-0;
            &:not(:last-child) {
                margin-right: var(--space-2);
            }
            &.active {
                .card-label {
                    @extend %bg-primary-50;
                    @extend %neutral-0;
                }
            }
        }
        .card-label {
            @include border(1, neutral-20, 10);
            @extend %p-2;
            @extend %mb-0;
            @extend %gap-3;
        }
    }
    .swiper {
        @extend %mx-2;
        &-button {
            &-prev,
            &-next {
                justify-content: center;
            }
        }
    }
    .toastui-calendar {
        &-day-name-item {
            font-weight: 700;
        }
        &-weekday-event {
            @extend %primary-50;
            @extend %bg-primary-0;
            @extend %pr-2;
            @include border(3, primary-50, 10, left);
        }
        &-daygrid-cell {
            &.today {
                &::before {
                    content: "";
                    @extend %w-100;
                    @extend %h-100;
                    @extend %position-t-l;
                    @extend %bg-neutral-20;
                }
                .toastui-calendar-grid-cell-date {
                    span {
                        @extend %primary-50;
                    }
                }
            }
        }
        &-grid-cell-date {
            font-weight: 700;
            @extend %relative;
        }
        &-floating-layer {
            @extend %d-none;
        }
        &-grid-cell-more-events {
            @include border(2, primary-50, 10, bottom);
            @extend %w-100;
            @extend %h-100;
            @extend %position-t-l;
        }
        &-weekday-grid-more-events {
            @extend %font-0;
        }
        &-see-more {
            @extend %border-radius-m;
        }
        &-see-more-header {
            @extend %relative;
            @extend %text-center;
        }
        &-more-title-date {
            font-weight: 700;
            @extend %neutral-100;
            @extend %mb-1;
        }
        &-more-title-day {
            @extend %neutral-70;
        }
        &-popup-button {
            &.toastui-calendar-popup-close {
                right: 2rem;
                top: calc(100% - 2.5rem);
            }
            &.toastui-calendar-popup-close::after {
                @include icon(close, 24);
                @extend %primary-50;
            }
        }
        &-month-more-list {
            @include custom-scroll();
            @extend %relative;
        }
    }
    .calendar {
        &-title {
            @include border(1, neutral-20, 10, top);
            @extend %px-4;
            @extend %py-5;
            @extend %mb-0;
            @extend %primary-50;
            @extend %text-l;
            .day-name {
                @extend %text-lb;
                @extend %mr-2;
                @extend %uppercase;
            }
        }
        &-event-item {
            &:hover {
                overflow: visible;
                white-space: normal;
                position: absolute;
                width: 100%;
                max-width: 87%;
                @extend %bg-primary-50;
                @extend %neutral-0;
                @extend %zindex-calendar;
            }
        }
    }
    .card-listing-wrapper,
    .calendar-list-view {
        .card-label {
            min-height: 3.2rem;
            display: inline-flex;
            color: var(--primary-50);
            background-color: var(--primary-0);
            @extend %gap-2;
            @extend %btn-fill-text-icon;
            @extend %relative;
            @extend %zindex-calendar;
            &::after {
                @include icon(live, 16);
            }
            &:hover {
                @extend %neutral-0;
            }
        }
    }
    .show-events {
        visibility: visible;
        position: static;
        top: 0;
        left: 0;
        width: 100%
    }
    .hide-events {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%
    }
}
.waf-eventlisting,.waf-event-calendar{
    .body {
        &-filter-content {
            @extend %m-y-20-8;
            @extend %p-x-4-4;
            @extend %p-y-6-6;
            @include large-wrapper();
        }
        &-tabs-container {
            overflow: hidden;
        }
    }
    .body-filter-content,.body-tabs-container,.card-listing-wrapper{
        @extend %border-radius-m;
        @extend %bg-neutral-0;

    }
}
.waf-event-calendar.waf-eventlisting {
    .head-tab-list {
        @extend %mb-6;
    }
    .card {
        &-item:first-child {
            border-top: 0;
        }
        // &-right {
        //     @extend %flex-column;
        //     > a {
        //         order: -1;
        //     }
        // }
        // &-meta {
        //     @extend %my-1;
        // }
        // &-title {
        //     order: -1;
        // }
        &-date .year {
            @extend %my-1;
        }
    }
}
@include mq(col-tablet) {
    .waf-eventlisting {
        &:has(.calendar-view.active) {
            .body {
                &-filter-content {
                    padding: var(--space-0);
                    margin-block: var(--space-18);
                }
                &-tabs-container {
                    padding-top: 10rem;
                }
            }
            .filter-section {
                margin-bottom: -20rem;
            }
        }
        .meta {
            &-category {
                width: calc(var(--window-inner-width) - 19rem);
            }
        }
    }
}
@include mq(col-lg) {
    .waf-eventlisting {
        .card {
            &-item {
                cursor: pointer;
                padding-inline: var(--space-6);
            }
            &-meta {
                gap: var(--space-6);
                @include flex-config(flex, row, flex-start, center);
            }
            &-right {
                width: calc(100% - 7.7rem);
                padding-right: 32rem;
            }
            &-title {
                width: auto;
                margin-bottom: 0;
                overflow: visible;
                text-overflow: unset;
                white-space: unset;
                font-size: 2rem;
            }
            &-label {
                display: inline-flex;
                margin-left: var(--space-4);
            }
            &-tag {
                margin-top: 0;
                display: inline-flex;
                @include position-combo(y-center, var(--space-6));
                .btn-site {
                    z-index: 1;
                }
            }
        }
        .tabs-options-list {
            .card-label {
                margin-left: 0;
            }
        }
        .meta {
            font-size: 1.4rem;
            &::before {
                font-size: 2.4rem;
            }
            &-origin {
                white-space: nowrap;
            }
            &-category {
                &::before {
                    vertical-align: middle;
                }
            }
        }
        .toastui-calendar {
            &-floating-layer {
                display: block;
            }
            &-grid-cell-more-events {
                width: auto;
                height: auto;
                position: relative;
                top: unset;
                left: unset;
                border-bottom: 0;
            }
            &-weekday-grid-more-events {
                font-size: 1.1rem;
            }
        }
        .calendar-title {
            display: none;
        }
    }
}