@use "../config/" as *;
.sticky-container-eventlisting-hero {
    & > div {
        @extend %p-0;
    }
    .layout-wrapper {
        max-width: 100%;
    }
}
.waf-eventlisting-hero {
    border-radius: 0 0 var(--space-4) var(--space-4);
    @extend %hidden;
    @extend %relative;
    @extend %common-gradient;
    &.waf-component {
        @extend %p-0;
    }
    .title {
        @include default-wrapper();
        @extend %neutral-0;
        @extend %title-30;
        @extend %mb-0;
        @extend %zindex-overlay-icon;
    }
    .banner {
        &-wrapper {
            // height: var(--window-inner-height);
            height: 50rem;
        }
        &-logo {
            @extend %h-100;
            img {
                object-fit: cover;
                object-position: center;
                @extend %h-100;
            }
        }
        &-content {
            // padding-top: calc((var(--window-inner-height) / 2) - var(--header-height) - 4rem);
            @include position-combo(x-center, var(--header-height), 0);
            @extend %flex-column-c-c;
        }
    }
}
@include mq(col-tablet) {
    .waf-eventlisting-hero {
        border-radius: 0 0 var(--space-8) var(--space-8);
        .title {
            font-size: 5.6rem;
        }
        .banner {
            &-wrapper {
                height: 60rem;
            }
            &-content {
                // padding-top: 26rem;
                top: calc(var(--header-height) + var(--header-redirect-height));
                &::after {
                    height: calc(100% + var(--header-height) + var(--header-redirect-height));
                }
            }
        }
    }
}